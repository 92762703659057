import { Theme } from "@mui/material/styles"
import React from "react"
import { BrowserRouter } from "react-router-dom"
import { Admin, combineDataProviders, Resource } from "react-admin"
import { Auth0Client } from "@auth0/auth0-spa-js"
// import { Auth0AuthProvider } from "ra-auth-auth0"

import groupMembersResource from "resources/iam/group_members"
import groupsResource from "resources/iam/groups"
import guestTemplateResource from "resources/real_estate/guest_templates"
import listedPropertiesResource from "resources/real_estate/listed_properties"
import providerAccountsResource from "resources/real_estate/provider_accounts"
import tagsResource from "resources/tag_management/tags"
import appConfig from "../config/app.config"
import { i18nProvider } from "../i18n"
import billGroupsResource from "../resources/billing/bill_groups"
import billLineItemsResource from "../resources/billing/bill_line_items"
import billPaymentStatusesResource from "../resources/billing/bill_payment_statuses"
import billStatusesResource from "../resources/billing/bill_statuses"
import billingIssuerProfilesResource from "../resources/billing/billing_issuer_profiles"
import billingRecipientProfilesResource from "../resources/billing/billing_recipient_profiles"
import billsResource from "../resources/billing/bills"
import productCategoriesResource from "../resources/billing/product_categories"
import productPricesResource from "../resources/billing/product_prices"
import productsResource from "../resources/billing/products"
import userResource from "../resources/iam/users"
import customerMembershipsResource from "../resources/real_estate/customer_memberships"
import ownersResource from "../resources/real_estate/owners"
import ownershipsResource from "../resources/real_estate/ownerships"
import propertiesResource from "../resources/real_estate/properties"
import propertySharesResource from "../resources/real_estate/shares"
import calendarEntriesResource from "../resources/scheduling/calendar_entries"
import calendarEntryGuestsResource from "../resources/scheduling/calendar_entry_guests"
import calendarMembersResource from "../resources/scheduling/calendar_members"
import calendarsResource from "../resources/scheduling/calendars"
import entryStatusesResource from "../resources/scheduling/entry_statuses"
import propertyProfilesResource from "../resources/scheduling/property_profiles"
import quotasSharesResource from "../resources/scheduling/quota_shares"
import quotasResource from "../resources/scheduling/quotas"
import quotaLimitsResource from "../resources/scheduling/quota_limits"
import companiesResource from "resources/real_estate/companies"
import { CustomLayout } from "./CustomLayout"
import { Dashboard } from "./Dashboard/Dashboard"
import {
  Auth0AuthProvider,
  httpClient,
  nestCrudDataProviderFactory,
  restApiDataProviderFactory,
} from "./providers"
import propertyUsagesResource from "resources/scheduling/property_usages"
import { standardRestApiDataProviderFactory } from "./providers/standardRestApiDataProvider/dataProviderFactory"
import calendarPropertiesResource from "resources/scheduling/calendar_properties"
import propertyAssigneesResource from "resources/real_estate/property_assignees"
import propertyContactsResource from "resources/real_estate/property_contacts"
import contactsResource from "resources/real_estate/contacts"

declare module "@mui/private-theming" {
  interface DefaultTheme extends Theme {}
}

const auth0 = new Auth0Client({
  domain: appConfig.auth0Domain,
  clientId: appConfig.auth0ClientId,
  cacheLocation: "localstorage",
  authorizationParams: {
    audience: appConfig.auth0Audience,
  },
})
const authProvider = Auth0AuthProvider(auth0)
const auth0HttpClient = httpClient(auth0)

const nestCrudDataProvider = nestCrudDataProviderFactory(
  appConfig.apiBaseUrl + "/v1",
  auth0HttpClient,
)
const restDataProvider = restApiDataProviderFactory(appConfig.apiBaseUrl + "/v1", auth0HttpClient)

const standardRestDataProvider = standardRestApiDataProviderFactory(
  appConfig.apiBaseUrl + "/v1",
  auth0HttpClient,
)

const standardRestDataProviderV2 = standardRestApiDataProviderFactory(
  `${appConfig.apiBaseUrl}/v2`,
  auth0HttpClient,
)

const dataProvider = combineDataProviders((resource) => {
  switch (resource) {
    case "groups":
    case "group_members":
    case "scheduling_reports/property_season_utilization":
    case "scheduling_reports/property_season_distribution":
      return restDataProvider

    case "rental/listed_properties":
    case "rental/provider_accounts":
    case "calendars":
    case "calendar_properties":
    case "property_assignees":
    case "contacts":
    case "companies":
    case "property_contacts":
    case "quota_limits":
    case "booking_quotas":
    case "booking_quota_shares":
    case "property_usages":
    case "property_profiles":
    case "calendar_entry_statuses":
    case "calendar_members":
    case "calendar_entry_guests":
      return standardRestDataProvider
    case "properties":
    case "customers":
      return standardRestDataProviderV2
    default:
      return nestCrudDataProvider
  }
})

export const App: React.FC = () => {
  if (!auth0) {
    return <div>Loading...</div>
  }

  return (
    <BrowserRouter>
      <Admin
        dataProvider={dataProvider}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        // customReducers={{ theme: themeReducer }}
        // customRoutes={routes}
        // loginPage={Auth0LoginPage} // TODO: Could use custom login page to call Auth0
        layout={CustomLayout}
        dashboard={Dashboard}
        disableTelemetry
      >
        {/*
    <CustomRoutes noLayout>
      <Route path="/configuration" element={<ConfigurationPage />} />
    </CustomRoutes>
    */}

        {/* Scheduling */}
        <Resource
          {...calendarsResource}
          name="calendars"
          options={{ label: "Calendars", group: "Scheduling" }}
        />

        <Resource
          {...calendarPropertiesResource}
          name="calendar_properties"
          options={{ label: "Calendar Properties", group: "Scheduling" }}
        />

        <Resource
          {...calendarMembersResource}
          name="calendar_members"
          options={{ label: "Calendar Member", group: "Scheduling" }}
        />
        <Resource
          {...calendarEntriesResource}
          name="calendar_entries"
          options={{ label: "Events", group: "Scheduling" }}
        />
        <Resource
          {...calendarEntryGuestsResource}
          name="calendar_entry_guests"
          options={{ label: "Calendar Entry Guests", group: "Scheduling" }}
        />
        <Resource
          {...entryStatusesResource}
          name="calendar_entry_statuses"
          options={{ label: "Event Statuses", group: "Scheduling" }}
        />
        <Resource
          {...quotasResource}
          name="booking_quotas"
          options={{ label: "Quotas", group: "Scheduling" }}
        />

        <Resource
          {...quotaLimitsResource}
          name="quota_limits"
          options={{ label: "Quota Limits", group: "Scheduling" }}
        />

        <Resource
          {...quotasSharesResource}
          name="booking_quota_shares"
          options={{ label: "Quota Shares", group: "Scheduling" }}
        />

        <Resource
          {...propertyProfilesResource}
          name="property_profiles"
          options={{ label: "Property Profiles", group: "Scheduling" }}
        />

        <Resource
          {...propertyUsagesResource}
          name="property_usages"
          options={{ label: "Property Usages", group: "Scheduling" }}
        />

        {/* RealEstate */}
        <Resource
          {...propertyAssigneesResource}
          name="property_assignees"
          options={{ label: "Property Assignees", group: "Real Estate" }}
        />

        <Resource
          {...propertyContactsResource}
          name="property_contacts"
          options={{ label: "Property Contacts", group: "Real Estate" }}
        />

        <Resource
          {...ownersResource}
          name="customers"
          options={{ label: "Owners", group: "Real Estate" }}
        />
        <Resource
          {...ownershipsResource}
          name="ownerships"
          options={{ label: "Ownerships", group: "Real Estate" }}
        />
        <Resource
          {...customerMembershipsResource}
          name="customer_memberships"
          options={{ label: "Customer Memberships", group: "Real Estate" }}
        />
        <Resource
          {...propertiesResource}
          name="properties"
          options={{ label: "Properties", group: "Real Estate" }}
        />

        <Resource
          {...contactsResource}
          name="contacts"
          options={{ label: "contacts", group: "Real Estate" }}
        />

        <Resource
          {...companiesResource}
          name="companies"
          options={{ label: "Companies", group: "Real Estate" }}
        />

        <Resource
          {...propertySharesResource}
          name="property_shares"
          options={{ label: "Property Shares", group: "Real Estate" }}
        />
        <Resource
          {...guestTemplateResource}
          name="guest_templates"
          options={{ label: "Guest templates", group: "Real Estate" }}
        />

        <Resource
          {...providerAccountsResource}
          name="rental/provider_accounts"
          options={{ label: "Rental Provider accounts", group: "Real Estate" }}
        />

        <Resource
          {...listedPropertiesResource}
          name="rental/listed_properties"
          options={{ label: "Rental Listed properties", group: "Real Estate" }}
        />

        {/* Billing */}
        <Resource
          {...productsResource}
          name="products"
          options={{ label: "Products", group: "Billing" }}
        />
        <Resource
          {...productCategoriesResource}
          name="product_categories"
          options={{ label: "Product Categories", group: "Billing" }}
        />
        <Resource
          {...productPricesResource}
          name="product_prices"
          options={{ label: "Product Prices", group: "Billing" }}
        />

        <Resource {...billsResource} name="bills" options={{ label: "Bills", group: "Billing" }} />
        <Resource
          {...billStatusesResource}
          name="bill_statuses"
          options={{ label: "Bill Status", group: "Billing" }}
        />
        <Resource
          {...billPaymentStatusesResource}
          name="bill_payment_statuses"
          options={{ label: "Bill Payment Status", group: "Billing" }}
        />
        <Resource
          {...billGroupsResource}
          name="bill_groups"
          options={{ label: "Bill Groups", group: "Billing" }}
        />
        <Resource
          {...billLineItemsResource}
          name="bill_line_items"
          options={{ label: "Bill Line Items", group: "Billing" }}
        />
        <Resource
          {...billingIssuerProfilesResource}
          name="billing_issuer_profiles"
          options={{ label: "Billing Issuer Profiles", group: "Billing" }}
        />
        <Resource
          {...billingRecipientProfilesResource}
          name="billing_recipient_profiles"
          options={{ label: "Billing Recipient Profiles", group: "Billing" }}
        />

        {/* Tags */}
        <Resource {...tagsResource} name="tags" options={{ label: "Tags list", group: "tags" }} />

        {/* IaM */}
        <Resource {...userResource} name="users" options={{ label: "Users", group: "IAM" }} />
        <Resource {...groupsResource} name="groups" options={{ label: "Groups", group: "IAM" }} />
        <Resource
          {...groupMembersResource}
          name="group_members"
          options={{ label: "Groups", group: "IAM" }}
        />

        {/* Reports */}

        <Resource name="scheduling_reports/property_season_distribution" />
        <Resource name="scheduling_reports/property_season_utilization" />
      </Admin>
    </BrowserRouter>
  )
}
