import { Info } from "@mui/icons-material"
import { Card, CardContent, LinearProgress, Stack, Typography } from "@mui/material"
import appConfig from "config/app.config"
import {
  BooleanField,
  CreateButton,
  DateInput,
  EditButton,
  RedirectionSideEffect,
  ShowButton,
  SimpleShowLayout,
  TopToolbar,
  useGetOne,
  useRecordContext,
  useShowContext,
  FilterButton,
  PaginationProps,
  Pagination,
} from "react-admin"
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  List,
  ReferenceManyField,
  Show,
  TabbedShowLayout,
  NumberField,
  Tab,
  TextField,
} from "react-admin"
import { UserRefField } from "resources/iam/users"
import { PropertyRefField, PropertyRefInput } from "resources/real_estate/properties"
import EntryStatusField from "resources/scheduling/calendar_entries/fields/EntryStatusField"

import { BillDto, BillStatusEnum } from "../../../types/Bill"
import { ICalendarEntry } from "../../../types/ICalendarEntry"
import { OwnerRefField, OwnerRefInput } from "../../real_estate/owners"
import { CalendarEntryRefField, CalendarEntryRefInput } from "../../scheduling/calendar_entries"
import PaymentStatusField from "../bill_payment_statuses/fields/PaymentStatusField"
import { BillPaymentStatusSelectInput } from "../bill_payment_statuses/inputs/BillPaymentStatusSelectInput"
import BillStatusField from "../bill_statuses/fields/BillStatusField"
import { BillStatusSelectInput } from "../bill_statuses/inputs/BillStatusSelectInput"
import { BillCreateForm } from "./BillCreateForm"
import { BillEditForm } from "./BillEditForm"
import { BillTitle } from "./BillTitle"
import { BillsDocuments } from "./BillsDocuments"
import { MoneyField } from "./MoneyField"
import { AddBillPaymentStatusButton } from "./buttons/AddBillPaymentStatusButton"
import { AddBillStatusButton } from "./buttons/AddBillStatusButton"
import { AddGroupButton } from "./buttons/AddGroupButton"
import { AddProductButton } from "./buttons/AddProductButton"
import GenerateUniqueNumberButton from "./buttons/GenerateUniqueNumberButton"
import BookingBillField from "./fields/BookingBillField"

import { DownloadButton } from "./buttons/DownloadButton"

const billFilters = [
  <CalendarEntryRefInput source="booking_id" />,
  <OwnerRefInput source="customer_id" alwaysOn />,
  <PropertyRefInput source="property_id" alwaysOn />,
  <BillStatusSelectInput label="Bill Status" source="current_status" alwaysOn />,
  <BillPaymentStatusSelectInput label="Payment Status" source="current_payment_status" alwaysOn />,
  <DateInput source="period_start" alwaysOn />,
  <DateInput source="period_end" alwaysOn />,
  <DateInput source="created_at_gte" alwaysOn label="Created at or after" />,
  <DateInput source="created_at_lte" alwaysOn label="Created at or before" />,
]

const ListActions = () => {
  return (
    <TopToolbar>
      <DownloadButton name="Download CSV" resourceUrl="v1/billing_reports/bills_datev_summary" />
      <DownloadButton name="Download ZIP" resourceUrl="v1/bills/aggregated_files" />
      <FilterButton />
      <CreateButton />
    </TopToolbar>
  )
}

const BillPagination = (props: PaginationProps) => (
  <Pagination rowsPerPageOptions={appConfig.paginationOptions} {...props} />
)

export const BillList = () => (
  <List
    filters={billFilters}
    exporter={false}
    actions={<ListActions />}
    pagination={<BillPagination />}
    perPage={appConfig.paginationOptions[0]}
    sort={{ field: "created_at", order: "DESC" }}
  >
    <Datagrid bulkActionButtons={false} rowClick="show">
      <CalendarEntryRefField source="booking_id" />
      <OwnerRefField source="customer_id" />
      <PropertyRefField source="property_id" />
      <TextField source="unique_number" />
      <BillStatusField source="current_status" />
      <PaymentStatusField source="current_payment_status" />
      <DateField source="check_in_day" />
      <DateField source="check_out_day" />
      <TextField source="document_type" />
      {/* <MoneyField source="subtotal" /> */}
      {/* <MoneyField source="vat" /> */}
      <MoneyField source="total" sortBy="total_amount" />
      <MoneyField source="due" sortBy="due_amount" />
      <TextField source="internal_notes" />
      <UserRefField source="created_by" />
      <DateField source="created_at" showTime />
      <ShowButton />
    </Datagrid>
  </List>
)

const ShowAside = () => {
  const record = useRecordContext<BillDto>()

  const { data: calendarEntry, isLoading } = useGetOne<ICalendarEntry>(
    "calendar_entries",
    {
      id: record?.booking_id,
    },
    { enabled: !!record?.booking_id },
  )

  return (
    <div style={{ width: 300, margin: "1em" }}>
      <Card style={{ marginBottom: "1em" }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Booking Info
          </Typography>

          {isLoading && <LinearProgress />}

          <SimpleShowLayout record={calendarEntry}>
            <TextField source="type" />
            {calendarEntry?.type === "booking" && (
              <TextField record={calendarEntry} source="booking_type" />
            )}
            <EntryStatusField source="current_status" />

            <NumberField source="adults_count" />
            <NumberField source="children_count" />
            <NumberField source="infants_count" />
            <BooleanField source="has_pets" />

            <NumberField source="requested_laundry_packages_count" />
            <NumberField source="requested_bedrooms_count" />
            <NumberField source="local_tourism_tax_total_count" />

            <TextField source="contact_email" />
            <TextField source="contact_name" />
            <TextField source="contact_phone" />

            <TextField source="note" label="Notes from Customer" />
            <TextField source="housekeeping_notes" />
            <TextField source="internal_notes" />

            <TextField source="title" />
            <UserRefField />
          </SimpleShowLayout>
        </CardContent>
      </Card>

      <Typography variant="h6">
        <Info /> Bill Statuses
      </Typography>
      <Typography variant="body2" mb={1}>
        <b>Draft</b>: default status
      </Typography>
      <Typography variant="body2" mb={1}>
        <b>Approved</b>: all billing items complete and confirmed - set by operations.
      </Typography>
      <Typography variant="body2" mb={1}>
        <b>Finalized</b>: billing details correct, ready to start payment collection - set by
        accounting.
      </Typography>
      <Typography variant="body2" mb={1}>
        <b>Closed</b>: processing of this bill closed (payment completed or uncollectible) - set by
        accounting or automatically.
      </Typography>
      <Typography variant="body2" mb={1}>
        <b>Cancelled</b>: processing of this bill cancelled - set by accounting.
      </Typography>

      <Typography variant="h6" mt={2}>
        <Info /> Payment Statuses
      </Typography>
      <Typography variant="body2" mb={1}>
        <b>Draft</b>: default status. Unique number/invoice number needs to be set.
      </Typography>
      <Typography variant="body2" mb={1}>
        <b>Awaiting Payment</b>: documents have been sent to customer. Payment is pending (eg. bank
        transfer) or needs to be triggered (eg. SEPA).
      </Typography>
      <Typography variant="body2" mb={1}>
        <b>Past Due</b>: complete payment not received after passing the due date.
      </Typography>
      <Typography variant="body2" mb={1}>
        <b>Paid</b>: complete payment received.
      </Typography>
      <Typography variant="body2" mb={1}>
        <b>Voided</b>: document (invoice, payment request) has been voided. Bill needs to be
        cancelled.
      </Typography>
      <Typography variant="body2" mb={1}>
        <b>Uncollectible</b>: payment cannot be collected. Bill needs to be closed.
      </Typography>
    </div>
  )
}

const BillShowActions = () => {
  const { record } = useShowContext<BillDto>()

  return (
    <TopToolbar>
      <Stack direction="row" spacing={2}>
        <GenerateUniqueNumberButton record={record} />
        <AddBillStatusButton />
        {record?.current_status === BillStatusEnum.finalized && <AddBillPaymentStatusButton />}
        <EditButton label="Edit Bill" />
      </Stack>
    </TopToolbar>
  )
}

export const BillShow = () => (
  <Show actions={<BillShowActions />} title={<BillTitle />} aside={<ShowAside />}>
    <TabbedShowLayout spacing={2}>
      <Tab label="Bill Details">
        <CalendarEntryRefField source="booking_id" />
        <OwnerRefField source="customer_id" />
        <PropertyRefField source="property_id" />

        <BillStatusField source="current_status" />

        <TextField source="unique_number" />
        <DateField source="unique_number_created_at" showTime />
        <UserRefField source="unique_number_created_by" />

        <PaymentStatusField source="current_payment_status" />

        <DateField source="check_in_day" />
        <DateField source="check_out_day" />

        <TextField source="internal_notes" />

        <TextField source="timezone" />

        <BookingBillField />
      </Tab>

      <Tab label="Groups" path="groups">
        <div
          style={{
            textAlign: "center",
          }}
        >
          <AddGroupButton />
          <AddProductButton />
        </div>
        <ReferenceManyField
          reference="bill_groups"
          target="bill_id"
          sort={{ field: "created_at", order: "DESC" }}
          label={false}
        >
          <Datagrid bulkActionButtons={false} rowClick="show">
            <TextField source="title" />
            <TextField source="category_code" />
            <BooleanField source="is_condensed" />
            <NumberField source="vat_rate" />
            <MoneyField source="discount" />
            <NumberField source="discount_percentage" />
            <TextField source="billable_amount_from" />

            <MoneyField source="subtotal" />
            <MoneyField source="vat" />
            <MoneyField source="total" />
            <MoneyField source="deferred" />
            <MoneyField source="due" />

            <DateField source="created_at" showTime />

            <EditButton />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>

      <Tab label="Discount" path="discount">
        <NumberField source="discount_amount" />
        <NumberField source="discount_percentage" />
        <TextField source="discount_title" />
      </Tab>

      <Tab label="Statuses" path="statuses">
        <AddBillStatusButton />
        <ReferenceManyField
          reference="bill_statuses"
          target="bill_id"
          sort={{ field: "created_at", order: "DESC" }}
          label="Bill Statuses"
        >
          <Datagrid bulkActionButtons={false}>
            <BillStatusField source="status" />
            <TextField source="internal_notes" />
            <UserRefField source="created_by" />
            <DateField source="created_at" showTime />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>

        <AddBillPaymentStatusButton />
        <ReferenceManyField
          reference="bill_payment_statuses"
          target="bill_id"
          sort={{ field: "created_at", order: "DESC" }}
          label="Payment Statuses"
        >
          <Datagrid bulkActionButtons={false}>
            <PaymentStatusField source="status" />
            <TextField source="internal_notes" />
            <UserRefField source="created_by" />
            <DateField source="created_at" showTime />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>

      <Tab label="Payment">
        <PaymentStatusField source="current_payment_status" />
        <TextField source="collection_method" />
        <TextField source="payed_amount" />
        <TextField source="payed_amount_updated_at" />
        <TextField source="payed_amount_updated_by" />
      </Tab>

      <Tab label="PDF" path="pdf">
        <BillsDocuments />
      </Tab>

      <Tab label="Document Content">
        <TextField source="document_type" />
        <TextField source="unique_number" />
        <TextField source="language_code" />
        <DateField source="billing_date" />
        {/* <DateField source="supply_date" /> */}
        {/* <DateField source="delivery_date" /> */}
        <DateField source="period_start" />
        <DateField source="period_end" />
        <DateField source="due_date" />
        <NumberField source="due_days" />
        <TextField source="title_text" />
        <TextField source="intro_text" />
        <TextField source="footer_text" />
        <TextField source="closing_text" />
        <TextField source="description_text" />
        <TextField source="property_label" />
      </Tab>

      <Tab label="Issuer">
        <TextField source="issuer_name" />
        <TextField source="issuer_address1" />
        <TextField source="issuer_address2" />
        <TextField source="issuer_address_city" />
        <TextField source="issuer_address_postal_code" />
        <TextField source="issuer_address_country" />
        <TextField source="issuer_email" />
        <TextField source="issuer_phone" />
        <TextField source="issuer_tax_exempt" />
        <TextField source="issuer_tax_id" />
        <TextField source="issuer_vat_id" />
        <TextField source="issuer_commercial_register_number" />
        <TextField source="issuer_local_court" />

        <TextField source="bank_name" />
        <TextField source="bank_account_owner" />
        <TextField source="bank_iban" />
        <TextField source="bank_bic" />
        <TextField
          source="issuer_payment_account_ref"
          label="Payment provider (eg. Stripe) account reference"
        />
      </Tab>

      <Tab label="Recipient">
        <TextField source="recipient_name" />
        <TextField source="recipient_address1" />
        <TextField source="recipient_address2" />
        <TextField source="recipient_address_city" />
        <TextField source="recipient_address_postal_code" />
        <TextField source="recipient_address_country" />
        <TextField source="recipient_email" />
        <TextField source="recipient_phone" />
        <TextField source="recipient_tax_exempt" />
        <TextField source="recipient_tax_id" />
        <TextField source="recipient_vat_id" />
        <TextField source="recipient_commercial_register_number" />
        <TextField source="recipient_local_court" />

        <TextField
          source="recipient_payment_provider_ref"
          label="Payment provider (eg. Stripe) customer reference"
        />
        <BooleanField source="recipient_has_enabled_payment_provider" />
      </Tab>

      <Tab label="Meta">
        <TextField source="id" />
        <DateField showTime source="created_at" />
        <DateField showTime source="updated_at" />
        <UserRefField source="created_by" />
        <NumberField source="version" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

const redirect: RedirectionSideEffect = (resource, id, data) =>
  data ? (data.booking_id ? `calendar_entries/${data.booking_id}/show/billing` : "edit") : "show"

export const BillCreate = () => (
  <Create redirect={redirect}>
    <BillCreateForm
      isExisting={false}
      // defaultValues={{
      //   due_days: 14,
      //   title_text: "Rechnung",
      //   intro_text:
      //     "Ihr Aufenthalt vom {{ bill.period_start | date: '%D.%m.%Y' }} bis zum {{ bill.period_end | date: '%D.%m.%Y' }} in der {{ property.address_field_1 }}, {{ property.address_postal_code }} {{ property.address_city }}, {{ property.address_country }}",
      //   closing_text:
      //     "Bitte überweisen Sie innerhalb von {{ bill.due_days }} Tagen den zahlbaren Betrag mit dem Verwendungszweck {{ bill.unique_number }} auf das unten genannte Konto.",
      // }}
    />
  </Create>
)

export const BillEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show" title={<BillTitle />}>
    <BillEditForm isExisting />
  </Edit>
)
