import React from "react"
import {
  SimpleFormProps,
  TextInput,
  Labeled,
  required,
  TabbedForm,
  FormTab,
  BooleanInput,
  FormDataConsumer,
  DateInput,
} from "react-admin"
import { OwnerRefField, OwnerRefInput } from "resources/real_estate/owners"
import { PropertyRefInput } from "resources/real_estate/properties"
import { BookingTypeSelectInput } from "resources/scheduling/calendar_entries"

// const EditToolbar = (props: ToolbarProps) => {
//   const record = useRecordContext()

//   return (
//     <Toolbar {...props}>
//       <SaveButton />
//       <DeleteWithConfirmButton />
//     </Toolbar>
//   )
// }

type Props = Partial<SimpleFormProps> & {
  isExisting: boolean
}

export const RecipientProfileForm: React.FC<Props> = ({ isExisting = false }) => (
  <TabbedForm
  // toolbar={<EditToolbar />}
  >
    <FormTab label="Profile Details">
      {isExisting ? (
        <Labeled source="customer_id" fullWidth>
          <OwnerRefField source="customer_id" />
        </Labeled>
      ) : (
        <OwnerRefInput source="customer_id" validate={[required()]} fullWidth />
      )}

      <PropertyRefInput
        label="For Property"
        source="property_id"
        helperText="Leave empty for all properties. Defines to which property this profile is applicable."
      />

      {/* TODO: Enable when ready
      <OwnershipRefInput
        disabled
        source="ownership_id"
        helperText="(This field is not yet ready for usage)"
      />
      <CalendarEntryRefInput
        disabled
        source="booking_id"
        helperText="(This field is not yet ready for usage)"
      />
      */}
      <BookingTypeSelectInput
        label="For Booking Type"
        source="booking_type"
        helperText="Leave empty for all booking types. Defines to which type of bookings this profile is applicable."
      />

      {/* <DocumentTypeSelectInput source="default_document_type" /> */}

      <TextInput
        source="profile_name"
        label="Billing profile name"
        helperText="Can be empty. Descriptive name for the profile. May also be shown to the customer"
      />
      <TextInput source="internal_notes" multiline rows={3} />
    </FormTab>

    <FormTab label="Billing Contact">
      <TextInput source="full_name" helperText="Full person or company name" />
      <TextInput source="email" />
      <TextInput source="phone" />
      <TextInput source="address_field_1" helperText="Street and house number" />
      <TextInput source="address_field_2" />
      <TextInput source="address_city" />
      <TextInput source="address_postal_code" />
      <TextInput source="address_country" helperText="Full country name, eg. `Deutschland`" />
      <TextInput source="tax_id" label="Tax ID" helperText="Steuernummer" />
      <TextInput source="vat_id" label="VAT ID" helperText="USt. ID" />
      <TextInput source="commercial_register_number" helperText="Handelsregisternummer" />
      <TextInput source="local_court" helperText="Amtsgericht" />
      <TextInput
        disabled
        source="tax_exempt"
        helperText="(This field is not yet ready for usage)"
      />
    </FormTab>

    <FormTab label="Payment provider">
      <BooleanInput
        source="has_enabled_payment_provider"
        helperText="When enabled invoicing can be processed via the payment provider (eg. Stripe)"
      />
    </FormTab>

    <FormTab label="SEPA">
      <BooleanInput
        source="has_sepa"
        label="Has SEPA mandate signed"
        helperText="SEPA mandate/merchant company needs to fit to the company issuing the invoice or payment-request"
      />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.has_sepa && (
            <>
              <TextInput source="sepa_mandate_ref" />
              <TextInput source="sepa_merchant_name" />
              <DateInput source="sepa_signature_date" />
              <TextInput source="sepa_payment_type" />
            </>
          )
        }
      </FormDataConsumer>
    </FormTab>
  </TabbedForm>
)
