import {
  Create,
  Datagrid,
  DateField,
  Edit,
  List,
  ReferenceManyField,
  Show,
  TabbedShowLayout,
  NumberField,
  Tab,
  TextField,
  ShowButton,
  EditButton,
  RedirectionSideEffect,
} from "react-admin"
import { PropertyRefField } from "resources/real_estate/properties"

import { IssuerProfileForm } from "./IssuerProfileForm"

// const listFilters = []

export const IssuerProfileList = () => (
  <List
    // filters={listFilters}
    exporter={false}
  >
    <Datagrid rowClick="show">
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
)

export const IssuerProfileShow = () => (
  <Show>
    <TabbedShowLayout spacing={2}>
      <Tab label="Profile Details">
        <PropertyRefField source="property_id" />

        <TextField source="booking_type" />

        <TextField source="profile_name" />
        <TextField source="internal_notes" />
      </Tab>

      <Tab label="Contact">
        <TextField source="full_name" />
        <TextField source="email" />
        <TextField source="phone" />
        <TextField source="address_field_1" />
        <TextField source="address_field_2" />
        <TextField source="address_city" />
        <TextField source="address_postal_code" />
        <TextField source="address_country" />
        <TextField source="tax_exempt" />
        <TextField source="tax_id" label="Tax ID" />
        <TextField source="vat_id" label="VAT ID" />
        <TextField source="commercial_register_number" />
        <TextField source="local_court" />
      </Tab>

      <Tab label="Bank">
        <TextField source="bank_account_owner" />
        <TextField source="bank_name" />
        <TextField source="bank_iban" />
        <TextField source="bank_bic" />
      </Tab>

      <Tab label="Bill Settings">
        <TextField source="default_document_type" />
        <TextField source="default_bill_property_label" />
        <TextField source="default_bill_due_days" />
        <TextField source="default_bill_intro_text" />
        <TextField
          source="payment_account_ref"
          label="Payment provider account_ref (eg. Stripe connected account)"
        />
      </Tab>
      <Tab label="Meta">
        <TextField source="id" />
        <DateField source="created_at" showTime />
        <DateField source="updated_at" showTime />
        <TextField source="created_by" />
        <NumberField source="version" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

const redirect: RedirectionSideEffect = (resource, id, data) =>
  data ? `properties/${data.property_id}/show/billing_issuer_profiles` : "show"

export const IssuerProfileCreate = () => (
  <Create redirect={redirect}>
    <IssuerProfileForm isExisting={false} />
  </Create>
)

export const IssuerProfileEdit = () => (
  <Edit mutationMode="pessimistic" redirect={redirect}>
    <IssuerProfileForm isExisting />
  </Edit>
)
